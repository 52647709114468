import React from 'react'
import { Link } from 'react-router-dom';
import OurClients from '../home/OurClients';


const WhyUs = () => {
    return (
        <>

            <div className='fix header-fixed-height'>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <section className="breadcrumb-area breadcrumb-bg" style={{ background: "url(assets/img/bg/breadcrumb_bg.jpg)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-content">
                                    <h2 className="title">Why Us</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Why Us</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb-shape-wrap">
                        <img src="assets/img/images/breadcrumb_shape01.png" alt />
                        <img src="assets/img/images/breadcrumb_shape02.png" alt />
                    </div>
                </section>

                <section className="overview-area-three section-py-120">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-9">
                                <div className="overview-img-three">
                                    <img src="assets/img/images/h9_overview_img01.jpg" alt="img" data-aos="fade-right" data-aos-delay={200} />
                                    <img src="assets/img/images/h9_overview_img02.jpg" alt="img" data-aos="fade-left" data-aos-delay={400} />
                                    <img src="assets/img/images/h9_overview_shape.png" alt="shape" />
                                    <div className="experience-year experience-year-two" data-aos="zoom-in" >
                                        <div className="icon">
                                            <i className="flaticon-trophy" />
                                        </div>
                                        <div className="content">
                                            {/* <h6 className="circle rotateme">1982 Business Gerow Since</h6> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="overview-content-three">
                                    <div className="section-title-four mb-20 tg-heading-subheading animation-style3">
                                        <span className="sub-title tg-element-title">Superior products, services, and solutions</span>
                                        <h2 className="title tg-element-title" style={{ fontSize: "24px" }}>A combination of experience and innovation</h2>
                                    </div>
                                    <p>Technology is always a trade-off between time and money. When you get it wrong, you might lose in more than one way. That’s why we’re so passionate about what we do! We’re not just developing applications; we’re building a future with you.</p>
                                    <div className="progress-wrap">
                                        <div className="progress-item">
                                            <h6 className="title">Consulting</h6>
                                            <div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow={85} aria-valuemin={0} aria-valuemax={100}>
                                                <div className="progress-bar wow slideInLeft" data-wow-delay=".1s" style={{ width: '85%' }}><span>85%</span></div>
                                            </div>
                                        </div>
                                        <div className="progress-item">
                                            <h6 className="title">Investment</h6>
                                            <div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow={76} aria-valuemin={0} aria-valuemax={100}>
                                                <div className="progress-bar wow slideInLeft" data-wow-delay=".2s" style={{ width: '76%' }}><span>76%</span></div>
                                            </div>
                                        </div>
                                        <div className="progress-item">
                                            <h6 className="title">Business</h6>
                                            <div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100}>
                                                <div className="progress-bar wow slideInLeft" data-wow-delay=".3s" style={{ width: '90%' }}><span>90%</span></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="services-area-twelve fix">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="services-inner-content">
                                    <div className="section-title-two white-title mb-15 tg-heading-subheading animation-style2">
                                        <span className="sub-title tg-element-title">Astute IT Services</span>
                                        <h2 className="title tg-element-title">Exceptional Quality</h2>
                                    </div>
                                    <p style={{ textAlign: "justify" }}>Astute IT Services, with its decades of combined experience, assures you of its unwavering commitment to quality, pricing, customer service, and more. Our products are painstakingly designed to guarantee each client's ease and personalization.</p>

                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="row gutter-24">
                                    <div className="col-md-6">
                                        <div className="services-item-eight">
                                            <div className="services-icon-eight">
                                                <i className="flaticon-piggy-bank" />
                                            </div>
                                            <div className="services-content-eight">
                                                <h2 className="title"><a to="services-details.html">One Team</a></h2>
                                                <p style={{ textAlign: "justify" }}>We think that cooperation is powerful. Every person's input is important and required for our success. People at Astute IT Services will always come first since they are what really make a difference.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="services-item-eight">
                                            <div className="services-icon-eight">
                                                <i className="flaticon-inspiration" />
                                            </div>
                                            <div className="services-content-eight">
                                                <h2 className="title"><a to="services-details.html">Always a Way</a></h2>
                                                <p style={{ textAlign: "justify" }}>We really think there is always a way out, even if it isn't immediately apparent. We are confident that, with enough adaptability, creativity, and willpower, we can discover a solution that satisfies everyone's needs.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="services-item-eight">
                                            <div className="services-icon-eight">
                                                <i className="flaticon-taxes" />
                                            </div>
                                            <div className="services-content-eight">
                                                <h2 className="title"><a to="services-details.html">First-Rate Results</a></h2>
                                                <p>To be great, one cannot take short cuts. We've learned from experience how important it is to get things right the first time. We are dedicated to always giving each client our best effort because of this.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="services-item-eight">
                                            <div className="services-icon-eight">
                                                <i className="flaticon-layers" />
                                            </div>
                                            <div className="services-content-eight">
                                                <h2 className="title"><a to="services-details.html">Industry Leadership</a></h2>
                                                <p>Astute IT Services was established by pioneers in the IT industry and is still leading the way in developing cutting-edge solutions, setting standards, and advancing our partners' and clients' futures.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services-shape-wrap">
                        <img src="assets/img/services/h6_services_shape01.png" alt="shape" data-aos="fade-down-left" data-aos-delay={400} />
                        <img src="assets/img/services/h6_services_shape02.png" alt="shape" data-aos="fade-up-right" data-aos-delay={400} />
                    </div>
                </section>


                <section className="about-area about-bg" style={{ background: "url(assets/img/bg/about_bg.jpg)" }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="about-img-wrap">
                                    <img src="assets/img/images/Vision-Mission.png" alt className="main-img" />
                                    {/* <img src="assets/img/images/about_img_shape01.png" alt /> */}
                                    <img src="assets/img/images/about_img_shape02.png" alt />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-content">
                                    <div className="section-title mb-25 tg-heading-subheading animation-style2">
                                        <span className="sub-title tg-element-title">Astute IT Services</span>
                                        <h2 className="title tg-element-title"> Astute IT Services Mission & Vision</h2>
                                    </div>
                                    <p style={{textAlign:"justify"}}>At Astute IT Services, our mission is to build resilient businesses with continuous innovation. Our 10+  years of industry experience have given us the technical and business knowledge to support you in building a future-proof business every step of the way.</p>
                                    <p style={{textAlign:"justify"}}>We’re prepared to help you face the future today—whatever comes tomorrow. We don’t just look at one problem at a time: we consider the entire process of running your business from beginning to end. You need to give your employees the tools they need to do their jobs well. We’re here to help.</p><br/>
                                    <p style={{textAlign:"justify"}}>Enabling companies to adopt cutting edge technology and completely changing the way that business is conducted.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <OurClients /> */}




            </div>
        </>
    )
}

export default WhyUs