import React from 'react'
import { Link } from "react-router-dom";


const FocusBusiness = () => {
    return (
        <>
            <section className="cta-area-seven fix">
                <div className="cta-bg" style={{ background: "url(assets/img/bg/h9_cta_bg.jpg)" }} />
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="section-title-two white-title text-center text-lg-start tg-heading-subheading animation-style3">
                                <h2 className="title tg-element-title">Focus on your business and leave the rest to us.</h2>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="cta-btn-two">
                                <Link to="/ManagedServices" className="btn btn-three border-btn">What We Do</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cta-shape">
                    <img src="assets/img/images/h9_cta_shape.png" alt="shape" data-aos="fade-down-left" data-aos-delay={400} />
                </div>
            </section>


        </>
    )
}

export default FocusBusiness