import React from 'react'
import { Link } from 'react-router-dom';


const ManagedServices = () => {
    return (
        <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <section className="breadcrumb-area breadcrumb-bg" style={{ background: "url(assets/img/bg/breadcrumb_bg.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-content">
                                <h2 className="title">Managed Services</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Managed Services</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-shape-wrap">
                    <img src="assets/img/images/breadcrumb_shape01.png" alt />
                    <img src="assets/img/images/breadcrumb_shape02.png" alt />
                </div>
            </section>

            <section className="about-area about-bg" data-background="assets/img/bg/about_bg.jpg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="about-img-wrap">
                                <img src="assets/img/images/about_img01.png" alt className="main-img" />
                                <img src="assets/img/images/about_img_shape01.png" alt />
                                <img src="assets/img/images/about_img_shape02.png" alt />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about-content">
                                <div className="section-title mb-25 tg-heading-subheading animation-style2">
                                    <span className="sub-title tg-element-title">Managed Services</span>
                                </div>
                                <p style={{ textAlign: "justify" }}>  Streamline the tech experience for your company. Intelligent IT Services Managed Services are your dependable allies, watching over your technology nonstop, around-the-clock. We identify and resolve problems before they become a hassle, cutting downtime and guaranteeing smooth operations—including protecting your data and making sure you constantly adhere to important legal requirements.</p>
                                <p style={{ textAlign: "justify" }}>Require adaptability? No matter the size or price range, Astute IT Services Managed Services has you covered. We can easily adjust to your changing business demands and technological landscape, adding or eliminating services as needed. Additionally, you avoid unforeseen expenses by receiving a fixed monthly charge in place of engaging internal IT workers. Get access to a group of IT professionals who are always available to assist, advise, and troubleshoot your business. You can concentrate on what you do best with Astute IT Services Managed Services, knowing that your technology is in good hands.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="features-item">
                                <div className="features-content">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="flaticon-puzzle-piece" />
                                        </div>
                                        <h2 className="title">Flexible Approach</h2>
                                    </div>
                                    <p style={{ textAlign: "justify" }}>When it comes to projects, money, scalability, and other issues, Astute IT Services meets you where you are.<br /><br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="features-item">
                                <div className="features-content">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="flaticon-inspiration" />
                                        </div>
                                        <h2 className="title">Coordinated Solutions</h2>
                                    </div>
                                    <p style={{ textAlign: "justify" }}>Enjoy the peace of mind that comes with end-to-end management and proactive problem-solving from Astute IT Services.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="features-item">
                                <div className="features-content">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="flaticon-profit" />
                                        </div>
                                        <h2 className="title">Boosted Efficiency</h2>
                                    </div>
                                    <p style={{ textAlign: "justify" }}>To boost efficiency and cut costs, make use of the resources and services provided by Astute IT Services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="features-area-two pt-80">
                <div className="container">
                    <div className="section-title-two text-center white-title mb-40 tg-heading-subheading animation-style3">
                        <span className="sub-title">Get the most out of Astute IT Services Managed Services</span>
                    </div>
                    <div className="features-item-wrap">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="features-item-two">
                                    <div className="features-icon-two">
                                        <i className="flaticon-profit" />
                                    </div>
                                    <div className="features-content-two">
                                        <h4 className="title">Rapid Deployment</h4>
                                        <p >Astute IT Services team is ready when you need it, and as accessible as your own I.T. department.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="features-item-two">
                                    <div className="features-icon-two">
                                        <i className="flaticon-investment" />
                                    </div>
                                    <div className="features-content-two">
                                        <h4 className="title">Complete Project Management:</h4>
                                        <p>Maintain a big-picture vision, while Astute IT Services efficiently manages the project from start to finish.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="features-item-two">
                                    <div className="features-icon-two">
                                        <i className="flaticon-taxes" />
                                    </div>
                                    <div className="features-content-two">
                                        <h4 className="title">Step-by-Step Reporting</h4>
                                        <p>With Astute IT Services, you’ll have comprehensive reporting at every stage of a project.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default ManagedServices