import React from 'react'

const HomeApproach = () => {
    return (
        <>
            <section className="features-area-three">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7">
                            <div className="section-title-two text-center mb-40 tg-heading-subheading animation-style1">
                                <span className="sub-title tg-element-title">Astute IT Services Approach</span>
                                {/* <h2 style={{ fontSize: "20px" }} className="title tg-element-title">The secret to distinctive and customized IT solutions for every situation</h2> */}
                            </div>
                        </div>
                        <p style={{ textAlign: "justify" }}>We are leaders in cutting edge technology for sturdy companies like yours. We at astute it services approach can help your business thrive in the future! We understand that it can be difficult to keep up with the rapid advancements in technology while running a business. That's where we come in: we can easily and effectively handle your trickiest business operations.</p>

                    </div>
                    <div className="features-item-wrap-two">
                        <div className="row justify-content-center">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="features-item-three">
                                    <div className="features-icon-three">
                                        <i className="flaticon-inspiration" />
                                    </div>
                                    <div className="features-content-three">
                                        <h2 className="title">Flexibility </h2>
                                        <p style={{ textAlign: "justify" }}>Astute IT services approach can meet your needs- whatever your industry, budget, or location.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="features-item-three">
                                    <div className="features-icon-three">
                                        <i className="flaticon-layers" />
                                    </div>
                                    <div className="features-content-three">
                                        <h2 className="title">Experience</h2>
                                        <p style={{ textAlign: "justify" }}>Whatever your needs, astute it services approach can accommodate them, no matter the location, industry, or budget.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="features-item-three">
                                    <div className="features-icon-three">
                                        <i className="flaticon-calculator" />
                                    </div>
                                    <div className="features-content-three">
                                        <h2 className="title">Culture</h2>
                                        <p style={{ textAlign: "justify" }}>To ensure our success as a team, we have fostered a culture of cooperation, curiosity, and tenacity throughout our organization.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="features-item-three">
                                    <div className="features-icon-three">
                                        <i className="flaticon-investment" />
                                    </div>
                                    <div className="features-content-three">
                                        <h2 className="title">Empowerment</h2>
                                        <p style={{ textAlign: "justify" }}>We help our clients make the right decisions for their businesses by being completely transparent with them.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="services-area-eight fix section-py-120">
                <div className="container custom-container-six">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title-two text-center white-title mb-40 tg-heading-subheading animation-style3">
                                <span className="sub-title">Astute IT Services Approach</span>
                                <h2 className="title tg-element-title" style={{ fontSize: "20px" }}>The key to unique and personalized IT solutions for any circumstance</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row services-active-two">
                        <div className="col-xl-3">
                            <div className="services-item-five shine-animate-item">
                                <div className="services-thumb-five">
                                    <a to="services-details.html" className="shine-animate"><img src="assets/img/services/hu_services_img01.jpg" alt="img" /></a>
                                </div>
                                <div className="services-content-five">
                                    <div className="services-content-five-top">
                                        <div className="icon">
                                            <i className="flaticon-briefcase" />
                                        </div>
                                        <h2 className="title"><a to="services-details.html">Experienced Support </a></h2>
                                    </div>
                                    <p>With over two decades of combined IT experience, our team can handle anything you throw our way. Rest easy knowing we’ve got your back no matter what!</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="services-item-five shine-animate-item">
                                <div className="services-thumb-five">
                                    <a to="services-details.html" className="shine-animate"><img src="assets/img/services/hu_services_img02.jpg" alt="img" /></a>
                                </div>
                                <div className="services-content-five">
                                    <div className="services-content-five-top">
                                        <div className="icon">
                                            <i className="flaticon-business-presentation" />
                                        </div>
                                        <h2 className="title"><a to="services-details.html">Affordable Solutions </a></h2>
                                    </div>
                                    <p>Paying for only what you use is one of the flexible ways that Astute IT Services supports. We offer solutions to suit your budget regardless of the size of your business.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="services-item-five shine-animate-item">
                                <div className="services-thumb-five">
                                    <a to="services-details.html" className="shine-animate"><img src="assets/img/services/hu_services_img03.jpg" alt="img" /></a>
                                </div>
                                <div className="services-content-five">
                                    <div className="services-content-five-top">
                                        <div className="icon">
                                            <i className="flaticon-round-table" />
                                        </div>
                                        <h2 className="title"><a to="services-details.html">Exceptional Culture </a></h2>
                                    </div>
                                    <p>Astute IT Services has always been about a commitment to teamwork, curiosity in the face of difficulties, and the tenacity to see every project through to completion!</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="services-item-five shine-animate-item">
                                <div className="services-thumb-five">
                                    <a to="services-details.html" className="shine-animate"><img src="assets/img/services/hu_services_img02.jpg" alt="img" /></a>
                                </div>
                                <div className="services-content-five">
                                    <div className="services-content-five-top">
                                        <div className="icon">
                                            <i className="flaticon-business-presentation" />
                                        </div>
                                        <h2 className="title"><a to="services-details.html">High-Quality Results </a></h2>
                                    </div>
                                    <p>We're committed to providing the best possible service. We don't settle for less at Astute IT Services because you shouldn't have to.</p>
                                    <br />
                                </div>
                            </div>
                        </div>


                        {/* <div className="col-xl-3">
                            <div className="services-item-five shine-animate-item">
                                <div className="services-thumb-five">
                                    <a to="services-details.html" className="shine-animate"><img src="assets/img/services/hu_services_img04.jpg" alt="img" /></a>
                                </div>
                                <div className="services-content-five">
                                    <div className="services-content-five-top">
                                        <div className="icon">
                                            <i className="flaticon-piggy-bank" />
                                        </div>
                                        <h2 className="title"><a to="services-details.html">Exclusive Tools </a></h2>
                                    </div>
                                    <p>A comprehensive range of tools, products, and services have been produced by Astute IT Services. </p>

                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
                <div className="services-shape-wrap">
                    <img src="assets/img/services/h6_services_shape01.png" alt="shape" data-aos="fade-down-left" data-aos-delay={400} />
                    <img src="assets/img/services/h6_services_shape02.png" alt="shape" data-aos="fade-up-right" data-aos-delay={400} />
                </div>
            </section>



        </>
    )
}

export default HomeApproach