import React from 'react'
import Footer from './layout/Footer'
import Header from './layout/Header'
import { Outlet } from 'react-router-dom'

const MainLayOut = () => {
    return (
        <>

            <Header />
            <Outlet />
            <Footer />

        </>
    )
}

export default MainLayOut