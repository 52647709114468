import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import Home from "./pages/Home";
import Contacts from "./pages/contacts/Contacts";
import About from "./pages/about/About";
import WhyUs from "./pages/whyus/WhyUs";
import ManagedServices from "./pages/managedservices/ManagedServices";
import ITConsulting from "./pages/itconsulting/ITConsulting";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  // useEffect(() => {
  //   if (
  //     window.location.href === "https://astuteitservices.com/" ||
  //     window.location.href === "https://astuteitservices.com" ||
  //     window.location.href === "https://astuteitservices.com/" ||
  //     window.location.href === "astuteitservices.com" ||
  //     window.location.href === "astuteitservices.com/" ||
  //     window.location.href === "https://astuteitservices.com"
  //   ) {
  //     window.location.replace("https://astuteitservices.com/");
  //   }
  // }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<MainLayOut />}>
          <Route index element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/WhyUs" element={<WhyUs />} />
          <Route path="/ManagedServices" element={<ManagedServices />} />
          <Route path="/ITConsulting" element={<ITConsulting />} />
          <Route path="/Contacts" element={<Contacts />} />
         
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
