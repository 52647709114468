import React from 'react'
import HomeBanner from './home/HomeBanner'
import HomeAirProducts from './home/HomeAirProducts'
import FocusBusiness from './home/FocusBusiness'
import HomeApproach from './home/HomeApproach'
import HomeAbout from './home/HomeAbout'
import OurClients from './home/OurClients'
import HomeServices from './home/HomeServices'

const Home = () => {
  return (
    <>

      <HomeBanner />
      <HomeAbout />
      {/* <OurClients /> */}
      {/* <HomeAirProducts /> */}
      <FocusBusiness />
      <HomeApproach />
      <HomeServices />

    </>
  )
}

export default Home