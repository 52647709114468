import React from 'react'
import { Link } from 'react-router-dom';
import WhatsappIcon from '../../pages/WhatsappIcon';


const Footer = () => {
    return (
        <>

            <footer>
                <div className="footer-area footer-bg" style={{ background: "url(assets/img/bg/footer_bg.jpg)" }}>
                    <div className="container">
                        <div className="footer-top">
                            <div className="row">
                                <div className="col-lg-3 col-md-7">
                                    <div className="footer-widget">
                                        <h4 className="fw-title">Information</h4>
                                        <div className="footer-info">
                                            <ul className="list-wrap">
                                                <li>
                                                    <div className="icon">
                                                        <i className="flaticon-pin" />
                                                    </div>
                                                    <div className="content">
                                                        <p>9932 Carter Dr McKinney Texas 75072, USA</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="flaticon-phone-call" />
                                                    </div>
                                                    <div className="content">
                                                        <a href="tel:+1 (469) 579-0511">+1 (469) 579-0511</a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="flaticon-mail" />
                                                    </div>
                                                    <div className="content">
                                                        <p>info@astuteitservices.com</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-2 col-md-5 col-sm-6">
                                    <div className="footer-widget">
                                        <h4 className="fw-title">AIR Apps</h4>
                                        <div className="footer-link">
                                            <ul className="list-wrap">
                                                <li><Link to="about.html">AIR Scrapex</Link></li>
                                                <li><Link to="">AIR Vault</Link></li>
                                                <li><Link to="">AIR Drive</Link></li>
                                                <li><Link to="blog.html">AIR Projects</Link></li>
                                                <li><Link to="">AIR Analytics</Link></li>
                                                <li><Link to="">AIR Atlas</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-3 col-md-5 col-sm-6">
                                    <div className="footer-widget">
                                        <h4 className="fw-title">Quick Links</h4>
                                        <div className="footer-link">
                                            <ul className="list-wrap">
                                                <li><Link to="/About">About us</Link></li>
                                                <li><Link to="/WhyUs">Why us</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-5 col-sm-6">
                                    <div className="footer-widget">
                                        <h4 className="fw-title">Solutions</h4>
                                        <div className="footer-link">
                                            <ul className="list-wrap">
                                                <li><Link to="/ManagedServices">Managed Services</Link></li>
                                                <li><Link to="/ITConsulting">IT Consulting</Link></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-5 col-sm-6">
                                    <div className="footer-widget">
                                        <h4 className="fw-title">Mpps</h4>
                                        <div className="footer-link">
                                            <div style={{ textDecoration: 'none', overflow: 'hidden', maxWidth: '100%', width: 400, height: 200 }}><div id="my-map-display" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=9932+Carter+Dr,+McKinney,+TX+75072,+USA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><Link className="embed-ded-maphtml" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="get-data-for-map">premium bootstrap themes</Link><style dangerouslySetInnerHTML={{ __html: "#my-map-display .text-marker{}.map-generator{max-width: 100%; max-height: 100%; background: none;" }} /></div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="left-sider">
                                        <div className="copyright-text">
                                            <p>2024 © Astute IT Services | Designed by <a href='tel:+918982471422' style={{ color: "white" }}>Web solution & digital marketing</a> </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="footer-social">
                                        <ul className="list-wrap">
                                        <li><a href="https://www.linkedin.com/company/astute-it-services" target='_blank'><i className="fab fa-linkedin"  /></a></li>
                                            <li><Link to="https://www.facebook.com/search/top?q=astute%20it%20services" target='_blank'><i className="fab fa-facebook-f" /></Link></li>
                                            {/* <li><Link to="/"><i className="fab fa-twitter" /></Link></li>
                                            <li><Link to="/"><i className="fab fa-instagram" /></Link></li>
                                            <li><Link to="/"><i className="fab fa-youtube" /></Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
            <WhatsappIcon />

        </>
    )
}

export default Footer