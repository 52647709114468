import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';


const Header = () => {
    const [ismobile, setIsmobile] = useState(0);

    return (
        <>

            <header className="transparent-header">
                <div className="heder-top-wrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="header-top-left">
                                    <ul className="list-wrap">
                                        <li><i className="flaticon-location" />9932 Carter Dr McKinney Texas 75072, USA</li>
                                        <li><i className="flaticon-mail" /><a href="mailto:info@astuteitservices.com">info@astuteitservices.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="header-top-right">
                                    <div className="header-contact">
                                        <a href="tel:+1 (469) 579-0511"><i className="flaticon-phone-call" />+1 (469) 579-0511</a>
                                    </div>
                                    <div className="header-social">
                                        <ul className="list-wrap">
                                            <li><a href="https://www.linkedin.com/company/astute-it-services" target='_blank'><i className="fab fa-linkedin" /></a></li>
                                            <li><Link to="https://www.facebook.com/search/top?q=astute%20it%20services" target='_blank'><i className="fab fa-facebook-f" /></Link></li>
                                            {/* <li><Link to="/"><i className="fab fa-twitter" /></Link></li>
                                            <li><Link to="/"><i className="fab fa-instagram" /></Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div id="sticky-header" className="menu-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mobile-nav-toggler"><i className="fas fa-bars" /></div>
                                <div className="menu-wrap">
                                    <nav className="menu-nav">
                                        <div className="logo">
                                            <Link to="/"><img src="assets/img/logo/a.png" alt="Logo" /></Link><br/>
                                            <Link  style={{marginLeft:"40px"}} to="/"><img src="assets/img/logo/b.png" alt="Logo" /></Link>
                                        </div>
                                        <div className="navbar-wrap main-menu d-none d-lg-flex">
                                            <ul className="navigation">
                                                <li><Link to="/">Home</Link></li>
                                                <li className=""><Link to="/About">About Us</Link>
                                                </li>
                                                <li className=""><Link to="/WhyUs">Why us</Link>
                                                </li>
                                                <li className="menu-item-has-children"><Link to="#">Solutions</Link>
                                                    <ul className="sub-menu">
                                                        <li><Link to="blog.html">Enterprise Cloud Applications</Link></li>
                                                        <li><Link to="blog-details.html">SAP Consulting</Link></li>
                                                        <li><Link to="blog-details.html">Oracle Consulting</Link></li>
                                                        <li><Link to="blog-details.html">Managed Services</Link></li>
                                                        <li><Link to="blog-details.html">IT Consulting</Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link to="/Contacts">contacts</Link></li>
                                            </ul>
                                        </div>
                                        <div className="header-action d-none d-md-block">
                                            <ul className="list-wrap">
                                                <li className="header-btn"><Link to="/Contacts" className="btn btn-two">Contact Us</Link></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className="mobile-menu">
                                    <nav className="menu-box">
                                        <div className="close-btn"><i className="fas fa-times" /></div>
                                        <div className="nav-logo">
                                            <Link to="/"><img src="assets/img/logo/logo.png" alt="Logo" /></Link>
                                        </div>
                                        <div className="mobile-search">
                                            <form action="#">
                                                <input type="text" placeholder="Search here..." />
                                                <button><i className="flaticon-search" /></button>
                                            </form>
                                        </div>
                                        <div className="menu-outer">
                                        </div>
                                        <div className="social-links">
                                            <ul className="clearfix list-wrap">
                                                <li><Link to="#"><i className="fab fa-facebook-f" /></Link></li>
                                                <li><Link to="#"><i className="fab fa-twitter" /></Link></li>
                                                <li><Link to="#"><i className="fab fa-instagram" /></Link></li>
                                                <li><Link to="#"><i className="fab fa-linkedin-in" /></Link></li>
                                                <li><Link to="#"><i className="fab fa-youtube" /></Link></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className="menu-backdrop" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-popup-wrap" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="search-close">
                        <span><i className="fas fa-times" /></span>
                    </div>
                    <div className="search-wrap text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="title">... Search Here ...</h2>
                                    <div className="search-form">
                                        <form action="#">
                                            <input type="text" name="search" placeholder="Type keywords here" />
                                            <button className="search-btn"><i className="fas fa-search" /></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <nav className="navbar navbar-expand-lg sticky-top">
                    <div className="container">
                        <Link className="navbar-brand">
                            <div className="logo-wrapper">
                                <Link className='d-flex justify-content-center align-items-center' to="/">
                                    <img src="assets/img/logo/d.png" className="img-fluid" alt="Edusquad" style={{ marginBottom: "30px" }} />
                                </Link>
                            </div>
                        </Link>
                        <div className={ismobile ? "moblink" : "desklink"} onClick={() => setIsmobile(false)}>
                            <ul>
                                <Link className=" text-decoration-none" to="/">
                                    <li  >Home</li>
                                </Link>
                                <Link className=" text-decoration-none" to="/About">
                                    <li className='li'  >About Us</li>
                                </Link>

                                <li>
                                    <Link className=" text-decoration-none" to="/WhyUs">
                                        <li > Why Us </li>
                                    </Link>
                                </li>
                                <li>
                                    <Link className=" text-decoration-none" to="/ManagedServices">
                                        <li > Managed Services </li>
                                    </Link>
                                </li>

                                <li>
                                    <Link className=" text-decoration-none" to="/ITConsulting">
                                        <li > IT Consulting</li>
                                    </Link>
                                </li>

                                <li>
                                    <Link className=" text-decoration-none" to="/Contacts">
                                        <li > Contact</li>
                                    </Link>
                                </li>

                            </ul>
                        </div>

                        <button className='vavbutn' onClick={() => setIsmobile(!ismobile)} style={{ marginBottom: "60px" }}>
                            {ismobile ? < IoMdClose /> : < GiHamburgerMenu />}
                        </button>
                    </div>
                </nav>
            </header>

        </>
    )
}

export default Header