import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';


import 'swiper/css';



import { Autoplay } from 'swiper/modules';

export default function SliderHomeBanner() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}


                modules={[Autoplay]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <div className="single-slider slider-bg" style={{ background: "url(assets/img/banner/banner_bg02.jpg)" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="slider-content">
                                        <span className="sub-title" data-animation="fadeInUp" data-delay=".2s">ASTUTE IT SERVICES</span>
                                        <h2 className="title" data-animation="fadeInUp" data-delay=".4s">Your partner in success.</h2>
                                        <p data-animation="fadeInUp" data-delay=".6s">Informed insights, flexible solutions, and continuous support.</p>
                                        <Link to="/ManagedServices" className="btn" data-animation="fadeInUp" data-delay=".8s">Our
                                            Services</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider-shape">
                            <img src="assets/img/banner/banner_shape.png" alt data-animation="zoomIn" data-delay=".8s" />
                        </div>
                    </div>
                </SwiperSlide>

                {/* <SwiperSlide>
                    <section className="banner-area-five has-animation">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-lg-7 col-md-10 order-0 order-lg-2">
                                    <div className="banner-img-five">
                                        <img src="assets/img/banner/h5_banner_main_img.png" alt className="main-img" />
                                        <img src="assets/img/banner/h5_banner_shape01.png" alt className="shape-one" data-aos="fade-up-left" data-aos-delay={600} />
                                        <img src="assets/img/banner/h5_banner_shape02.png" alt className="shape-two" />
                                        <img src="assets/img/banner/h5_banner_shape03.png" alt className="shape-three" />
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="banner-content-five">
                                        <h2 className="title" data-aos="fade-right" data-aos-delay={0}>IT Consulting 
                                            <span>
                                                <svg viewBox="0 0 183 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.1884 13.1749C27.3244 9.45935 96.6096 -9.08021 181.595 12.5919" stroke="#0055FF" strokeWidth={4} />
                                                </svg>
                                            </span> </h2>
                                        <p data-aos="fade-right" data-aos-delay={300}>Informed insights, flexible solutions, and continuous support. </p>
                                        <Link to="/ManagedServices" className="btn btn-three" data-aos="fade-right" data-aos-delay={600}>Our Services</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </SwiperSlide> */}
                <SwiperSlide>
                    <div className="single-slider slider-bg" style={{ background: "url(assets/img/banner/banner_bg.jpg)" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="slider-content">
                                        <span className="sub-title" data-animation="fadeInUp" data-delay=".2s">ASTUTE IT SERVICES</span>
                                        <h2 className="title" data-animation="fadeInUp" data-delay=".4s">Managed Services </h2>
                                        <p data-animation="fadeInUp" data-delay=".6s">Effort-based billing, flexible plans, and custom solutions at value-based rates.</p>
                                        <Link to="/ManagedServices" className="btn" data-animation="fadeInUp" data-delay=".8s">Our
                                            Services</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider-shape">
                            <img src="assets/img/banner/banner_shape.png" alt data-animation="zoomIn" data-delay=".8s" />
                        </div>
                    </div>
                </SwiperSlide>


                <SwiperSlide>
                    <div className="single-slider slider-bg" style={{ background: "url(assets/img/banner/1.png)" }}>
                        <div className="container">
                            <div className="row">
                            </div>
                        </div>
                        <div className="slider-shape">
                            <img src="assets/img/banner/banner_shape.png" alt data-animation="zoomIn" data-delay=".8s" />
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="single-slider slider-bg" style={{ background: "url(assets/img/banner/2.png)" }}>
                        <div className="container">
                            <div className="row">
                            </div>
                        </div>
                        <div className="slider-shape">
                            <img src="assets/img/banner/banner_shape.png" alt data-animation="zoomIn" data-delay=".8s" />
                        </div>
                    </div>
                </SwiperSlide>
              




            </Swiper>
        </>
    );
}
