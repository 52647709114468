import React from 'react'
import { Link } from 'react-router-dom';


const Contacts = () => {
    return (
        <>

            <div className='fix header-fixed-height'>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <section className="breadcrumb-area breadcrumb-bg" style={{ background: "url(assets/img/bg/breadcrumb_bg.jpg)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-content">
                                    <h2 className="title">Contact Us</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Contact</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb-shape-wrap">
                        <img src="assets/img/images/breadcrumb_shape01.png" alt />
                        <img src="assets/img/images/breadcrumb_shape02.png" alt />
                    </div>
                </section>
                <section className="inner-contact-area pt-120 pb-120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="inner-contact-img">
                                    <img src="assets/img/images/contact_img.jpg" alt />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="inner-contact-info">
                                    <h2 className="title">Our Office Address</h2>
                                    <div className="contact-info-item">
                                        <h5 className="title-two">USA Office</h5>
                                        <ul className="list-wrap">
                                            <li>9932 Carter Dr McKinney Texas 75072, USA</li>
                                            <li>+1(469)579-0511</li>
                                            <li>info@astuteitservices.com</li>
                                        </ul>
                                    </div>
                                    <div className="contact-info-item">
                                        <h5 className="title-two">India Office</h5>
                                        <ul className="list-wrap">
                                            <li>8-1-39/GS/A-5 Tolichowki hyderabad, telangana-500008</li>
                                            <li>+91 8686478478</li>
                                            <li>info@astuteitservices.com</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="contact-map container">
                    <div style={{ overflow: 'hidden', resize: 'none', maxWidth: '100%', width: 1300, height: 400 }}><div id="embed-map-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=9932+Carter+Dr+McKinney+Texas+75072&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="my-codefor-googlemap" href="https://www.bootstrapskins.com/themes" id="authorize-map-data">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#embed-map-canvas img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}" }} /></div>

                </div>
                <br />
                <br />
                <br />
            </div>

        </>
    )
}

export default Contacts