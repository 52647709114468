import React from 'react'
import { Link } from 'react-router-dom';
import FocusBusiness from '../home/FocusBusiness';
import HomeApproach from '../home/HomeApproach';
import OurClients from '../home/OurClients';


const About = () => {
    return (
        <>
            <div className='fix header-fixed-height'>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
                <section className="breadcrumb-area breadcrumb-bg" style={{ background: "url(assets/img/bg/breadcrumb_bg.jpg)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-content">
                                    <h2 className="title">About Us</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb-shape-wrap">
                        <img src="assets/img/images/breadcrumb_shape01.png" alt />
                        <img src="assets/img/images/breadcrumb_shape02.png" alt />
                    </div>
                </section>
                <section className="about-area-fourteen section-py-120">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-9 order-0 order-lg-2">
                                <div className="about-img-fourteen">
                                    <img src="assets/img/images/h8_about_img01.jpg" alt="img" data-aos="fade-left" data-aos-delay={400} />
                                    <img src="assets/img/images/h8_about_img02.jpg" alt="img" data-aos="fade-right" data-aos-delay={400} />
                                    <img src="assets/img/images/h8_about_img03.jpg" alt="img" data-aos="fade-up" data-aos-delay={500} />
                                    <div className="shape">
                                        <img src="assets/img/images/h8_about_shape01.png" alt="shape" data-aos="fade-up-left" data-aos-delay={900} />
                                        <img src="assets/img/images/h8_about_shape02.png" alt="shape" data-aos="zoom-in" data-aos-delay={700} />
                                        <img src="assets/img/images/h8_about_shape03.png" alt="shape" data-aos="zoom-in" data-aos-delay={600} className="heartbeat" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-content-nine">
                                    <div className="section-title-two mb-25 tg-heading-subheading animation-style1">
                                        <span className="sub-title tg-element-title">ASTUTE IT SERVICES</span>
                                        <h2 className="title tg-element-title">Powered by technology</h2>
                                    </div>
                                    <p style={{ textAlign: "justify" }}>For businesses just like yours, astute it services creates and maintains personalized applications. We are quickly growing our market share and have a strong base from which to build.</p>
                                    <p style={{ textAlign: "justify" }}> We are leaders in cutting-edge technology for tenacious companies. We at astute it services can help your business move into the future! We understand that managing a business and staying up to date with the swift changes in technology can be difficult. Here's where we can help: we can effectively and effortlessly manage your trickiest business tasks.</p>
                                    <p style={{ textAlign: "justify" }}> Our mission is to transform business processes and enable companies via the use of cutting-edge technology. Our combined industry experience of over a decade will guide you through every stage of the process, from development to deployment. </p>



                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="services-area-nine fix" style={{ background: "url(assets/img/bg/services_bg02.jpg)" }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-8">
                                <div className="section-title-two text-center text-md-start white-title mb-40 tg-heading-subheading animation-style2">
                                    <span className="sub-title tg-element-title">My Business Services</span>
                                    <h2 className="title tg-element-title" style={{ color: "#00194C" }}>Why Choose Us</h2>
                                </div>
                            </div>

                        </div>
                        <div className="services-item-wrap">
                            <div className="services-item-six" data-aos="fade-up" data-aos-delay={400}>
                                <div className="services-thumb-six">
                                    <a to="services-details.html"><img src="assets/img/services/h7_services_img01.jpg" alt="img" /></a>
                                </div>
                                <div className="services-content-six">
                                    <div className="services-content-six-top">
                                        <div className="services-icon-six">
                                            <i className="flaticon-briefcase-1" />
                                        </div>
                                        <h2 className="title"><a to="services-details.html" style={{ color: "#00194C" }}>Building Resilient Business</a></h2>
                                    </div>
                                    <p style={{ color: "#00194C" }}>With over a decade of combined industry expertise, we have the technical and business know-how to help you every step of the way as you develop a future-proof firm. Giving your staff the resources they require to perform their duties properly is essential. We are available to assist. Your robust company needs to start now.</p>

                                </div>
                            </div>
                            <div className="services-item-six" data-aos="fade-up" data-aos-delay={400}>
                                <div className="services-thumb-six">
                                    <a to="services-details.html"><img src="assets/img/services/h7_services_img02.jpg" alt="img" /></a>
                                </div>
                                <div className="services-content-six">
                                    <div className="services-content-six-top">
                                        <div className="services-icon-six">
                                            <i className="flaticon-calculator" />
                                        </div>
                                        <h2 className="title"><a to="services-details.html" style={{ color: "#00194C" }}>Continuous Innovation</a></h2>
                                    </div>
                                    <p style={{ color: "#00194C" }}>Customer growth has always been Astute IT Services' top priority. We're ready to support you in facing the future today, no matter what it holds. Solve issues holistically, taking into account both financial and human resource requirements. We take into account every step of managing your company's operations, not just one issue at a time.</p>

                                </div>
                            </div>
                            <div className="services-item-six" data-aos="fade-up" data-aos-delay={400}>
                                <div className="services-thumb-six">
                                    <a to="services-details.html"><img src="assets/img/services/h7_services_img03.jpg" alt="img" /></a>
                                </div>
                                <div className="services-content-six">
                                    <div className="services-content-six-top">
                                        <div className="services-icon-six">
                                            <i className="flaticon-inspiration" />
                                        </div>
                                        <h2 className="title"><a to="services-details.html" style={{ color: "#00194C" }}>Customer Support</a></h2>
                                    </div>
                                    <p style={{ color: "#00194C" }}>Each project is unique. We understand how crucial it is to find the ideal solution for your company, which entails going above and beyond your expectations. You can rely on us to get you there. Thousands of happy clients who have utilized our services entrust us with their business on a daily basis because we support them at every turn.</p>

                                </div>
                            </div>
                            <div className="services-item-six" data-aos="fade-up" data-aos-delay={400}>
                                <div className="services-thumb-six">
                                    <a to="services-details.html"><img src="assets/img/services/h7_services_img04.jpg" alt="img" /></a>
                                </div>
                                <div className="services-content-six">
                                    <div className="services-content-six-top">
                                        <div className="services-icon-six">
                                            <i className="flaticon-profit" />
                                        </div>
                                        <h2 className="title"><a to="services-details.html" style={{ color: "#00194C" }}>Single Solution</a></h2>
                                    </div>
                                    <p style={{ color: "#00194C" }}>We can handle a one-time evaluation of your current system or a comprehensive redesign. Our staff takes the time to determine the answer you require, create an action plan, and deliver outcomes quickly. Whether you're searching for a more conventional application management system or an agile, DevOps-based solution, Astute IT Services has the knowledge to guide you through your technological landscape.</p>

                                </div>
                            </div>


                            <div className="services-item-six" data-aos="fade-up" data-aos-delay={400}>
                                <div className="services-thumb-six">
                                    <a to="services-details.html"><img src="assets/img/services/h7_services_img01.jpg" alt="img" /></a>
                                </div>
                                <div className="services-content-six">
                                    <div className="services-content-six-top">
                                        <div className="services-icon-six">
                                            <i className="flaticon-briefcase-1" />
                                        </div>
                                        <h2 className="title"><a to="services-details.html" style={{ color: "#00194C" }}>Effective And Economical</a></h2>
                                    </div>
                                    <p style={{ color: "#00194C" }}>In every way, Astute IT Services provides unmatched value. To save you time, our team optimizes operations and streamlines intricate procedures. We increase your productivity using targeted methods and state-of-the-art technologies. Additionally, our line of AIR Products gives customers power without breaking the bank on several software licenses. Top-notch services at the finest value are provided by Astute IT Services, which combines experience with a commitment to client success.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services-shape-wrap">
                        <img src="assets/img/services/h6_services_shape01.png" alt="shape" data-aos="fade-down-left" data-aos-delay={400} />
                        <img src="assets/img/services/h6_services_shape02.png" alt="shape" data-aos="fade-up-right" data-aos-delay={400} />
                    </div>
                </section>
                <FocusBusiness />
                <HomeApproach />
                {/* <OurClients /> */}


            </div>
        </>
    )
}

export default About