import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import i18next from "i18next"
import SliderHomeApproach from '../../component/slidercomponent/SliderHomeApproach';


const HomeAbout = () => {
    const { t } = useTranslation();
    return (
        <>

            <section className="about-area-thirteen p-relative section-py-120">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="about-img-thirteen">
                                <img src="assets/img/images/hu_about_01.jpg" alt="img" data-aos="fade-right" data-aos-delay={400} />
                                <img src="assets/img/images/hu_about_02.jpg" alt="img" data-aos="fade-left" data-aos-delay={400} />
                                <img src="assets/img/images/hu_about_shape01.png" alt="img" data-aos="zoom-in" data-aos-delay={800} />
                                <div className="experience-wrap-two" data-aos="fade-up" data-aos-delay={800}>
                                    <h2 className="title">10+</h2>
                                    <span>Years Of <br /> Experience</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content-thirteen">
                                <div className="section-title-two mb-20 tg-heading-subheading animation-style3">
                                    <br/>
                                    <br/>
                                    <br/>
                                    <span className="sub-title">WHY CHOOSE US?</span>
                                    <h2 className="title tg-element-title">Our commitment to our clients</h2>
                                </div>
                                <p style={{ textAlign: "justify" }}>Astute IT services is a dallas-based staffing and application development firm. Many start-ups and mid-sized organizations benefit from our custom and ready-made design and development solutions. In the congested internet world, our innovative efforts enliven their business sites.</p>
                                <p style={{ textAlign: "justify" }}>With over a decade of combined expertise in the field, our unique strength lies in our capacity to seamlessly integrate our technological and business acumen to establish robust enterprises.</p>
                                <div className="about-inner-content mb-40">
                                    <div className="about-success-wrap">
                                        <ul className="list-wrap">
                                            <li>
                                                <div className="icon">
                                                    <i className="flaticon-family" />
                                                </div>
                                                <div className="content">
                                                    <h2 className="count"><span className="odometer" />99 %</h2>
                                                    <p> Happy Customer's</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <i className="flaticon-trophy" />
                                                </div>
                                                <div className="content">
                                                    <h2 className="count"><span className="odometer" />12 +</h2>
                                                    <p>Satisfied Award</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="about-list-two">
                                        <ul className="list-wrap">
                                            <li><i className="fas fa-arrow-right" /> 10+ Years of foundation</li>
                                            <li><i className="fas fa-arrow-right" /> 55+ Team members</li>
                                            <li><i className="fas fa-arrow-right" />2+ Locations worldwide</li>
                                        </ul>
                                    </div>
                                </div>
                                <Link to="/Contacts" className="btn btn-three">Contact with us</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-shape-wrap-six">
                    <img src="assets/img/images/request_shape.png" alt="shape" className="animationFramesOne" />
                    <img src="assets/img/images/h6_about_shape.png" alt="shape" data-aos="fade-left" data-aos-delay={400} />
                </div>
            </section>



          


        </>
    )
}

export default HomeAbout