import React from 'react'
import { Link } from "react-router-dom";


const HomeServices = () => {
    return (
        <>
            <section className="services-area-six">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title-two mb-60" style={{ textAlign: "center" }}>
                                <span className="sub-title" >SERVICES</span>
                                <h2 className="title" style={{ fontSize: "25px", textAlign: "center" }}>Solving IT challenges in every industry, every day.</h2>
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                            <div className="section-top-content mb-30">
                                <p>Morem ipsum dolor sit amet, consectetur adipiscing elita florai psum dolor amet, consectetuolor sit amet, consectetur adipiscing elita florai psum sit amet consecteturere.</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="row justify-content-center">

                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                            <div className="services-item-two">
                                <div className="services-thumb-two">
                                    <img src="assets/img/services/h2_services_img02.jpg" alt />
                                    <div className="item-shape">
                                        <img src="assets/img/services/services_item_shape.png" alt />
                                    </div>
                                </div>
                                <div className="services-content-two">
                                    <div className="icon">
                                        <i className="flaticon-calculator" />
                                    </div>
                                    <h2 className="title"><Link to="/ITConsulting">IT Consulting</Link></h2>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                            <div className="services-item-two">
                                <div className="services-thumb-two">
                                    <img src="assets/img/services/h2_services_img03.jpg" alt />
                                    <div className="item-shape">
                                        <img src="assets/img/services/services_item_shape.png" alt />
                                    </div>
                                </div>
                                <div className="services-content-two">
                                    <div className="icon">
                                        <i className="flaticon-money" />
                                    </div>
                                    <h2 className="title"><Link to="/ManagedServices">Managed Services</Link></h2>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                            <div className="services-item-two">
                                <div className="services-thumb-two">
                                    <img src="assets/img/services/h2_services_img04.jpg" alt />
                                    <div className="item-shape">
                                        <img src="assets/img/services/services_item_shape.png" alt />
                                    </div>
                                </div>
                                <div className="services-content-two">
                                    <div className="icon">
                                        <i className="flaticon-layers" />
                                    </div>
                                    <h2 className="title"><a to="services-details.html">SAP Consulting</a></h2>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                            <div className="services-item-two">
                                <div className="services-thumb-two">
                                    <img src="assets/img/services/h2_services_img01.jpg" alt />
                                    <div className="item-shape">
                                        <img src="assets/img/services/services_item_shape.png" alt />
                                    </div>
                                </div>
                                <div className="services-content-two">
                                    <div className="icon">
                                        <i className="flaticon-piggy-bank" />
                                    </div>
                                    <h2 className="title"><a to="services-details.html">Oracle Consulting</a></h2>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>


        </>
    )
}

export default HomeServices