import React from 'react'
import { Link } from 'react-router-dom';


const ITConsulting = () => {
    return (
        <>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <section className="breadcrumb-area breadcrumb-bg" style={{ background: "url(assets/img/bg/breadcrumb_bg.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-content">
                                <h2 className="title">IT Consulting</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">IT Consulting</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-shape-wrap">
                    <img src="assets/img/images/breadcrumb_shape01.png" alt />
                    <img src="assets/img/images/breadcrumb_shape02.png" alt />
                </div>
            </section>

            <section className="about-area about-bg" data-background="assets/img/bg/about_bg.jpg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="about-img-wrap">
                                <img src="assets/img/images/about_img01.png" alt className="main-img" />
                                <img src="assets/img/images/about_img_shape01.png" alt />
                                <img src="assets/img/images/about_img_shape02.png" alt />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about-content">
                                <div className="section-title mb-25 tg-heading-subheading animation-style2">
                                    <span className="sub-title tg-element-title">IT Consulting</span>
                                </div>
                                <p style={{ textAlign: "justify" }}>  IT professionals are excellent at converting technical speak into real advantages for your company. We take great satisfaction in our ability to solve problems and create unique technology solutions that enable our partners. We can handle everything from process optimization to the integration of state-of-the-art solutions to make sure your technology is perfectly in line with your objectives.</p>
                                <p style={{ textAlign: "justify" }}>A strategic roadmap for your goals is the first step in our individualized strategy. Skilled IT professionals assess your current configuration for flaws or inefficiencies and develop a tailored solution in response. Modern security measures are given first priority by Astute IT Services, which also looks for any attacks and protects your digital infrastructure from dangers. Maximizing the overall effectiveness and lifespan of your technology investment is the ultimate objective.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="features-item">
                                <div className="features-content">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="flaticon-puzzle-piece" />
                                        </div>
                                        <h2 className="title">Big-Picture Perspective</h2>
                                    </div>
                                    <p style={{ textAlign: "justify" }}>Astute IT Services handles the details while you maintain a high-level view.<br /><br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="features-item">
                                <div className="features-content">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="flaticon-inspiration" />
                                        </div>
                                        <h2 className="title">Seasoned Experts</h2>
                                    </div>
                                    <p style={{ textAlign: "justify" }}>Astute IT Services team is made up of industry leaders focused on your success.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="features-item">
                                <div className="features-content">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="flaticon-profit" />
                                        </div>
                                        <h2 className="title">Proactive Approach</h2>
                                    </div>
                                    <p style={{ textAlign: "justify" }}>Identify potential security risks, infrastructure weakness, and more with Astute IT Services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="features-area-two pt-80">
                <div className="container">
                    <div className="section-title-two text-center white-title mb-40 tg-heading-subheading animation-style3">
                        <span className="sub-title">Get what you need with Astute IT Services I.T. Consulting</span>
                    </div>
                    <div className="features-item-wrap">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="features-item-two">
                                    <div className="features-icon-two">
                                        <i className="flaticon-profit" />
                                    </div>
                                    <div className="features-content-two">
                                        <h4 className="title">Comprehensive Consultation</h4>
                                        <p >Take a deep dive into your company’s technical operations, current environment, and identify possible gaps.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="features-item-two">
                                    <div className="features-icon-two">
                                        <i className="flaticon-investment" />
                                    </div>
                                    <div className="features-content-two">
                                        <h4 className="title">Informed Insights:</h4>
                                        <p>Astute IT Services experts have seen it all, and can help you navigate any situation smoothly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="features-item-two">
                                    <div className="features-icon-two">
                                        <i className="flaticon-taxes" />
                                    </div>
                                    <div className="features-content-two">
                                        <h4 className="title">Flexible Solutions</h4>
                                        <p>Tailored to your company and adaptable in the face of change, Astute IT Services I.T. solutions scale with you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ITConsulting